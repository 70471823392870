<template>
  <div id="singleModule" style="background: #fff;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/joinUs4.png" alt="show" class="imgSize">
    </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'Productintroduce',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '员工风采',
      autoPlay: false,
    }
  },

  mounted() {},

  methods: {
    goChinaFish() {
      window.open('https://www.cfse-live.com')
    },
  },
}
</script>

<style lang="less" scoped>
.moduleContainer{
  position: relative;
  top: -40px;
}
.imgSize {
  width: 1200px;
  height: 670px;
  cursor: pointer;
}
</style>