<template>
  <div>
    <WmtHeader :activeKey='activeKey'></WmtHeader>
    <div id="singleModule" style="height:auto;">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/joinUsBanner.png" alt="" class="topbg">
    </div>

    <StaffDesc></StaffDesc>

    <!-- 员工风采 -->
    <StaffShow></StaffShow>

    <!-- 招聘计划 -->
    <JobDesc></JobDesc>
    <!-- 底部通栏 -->
    <WmtFooter></WmtFooter>
  </div>
</template>

<script>
import WmtHeader from '@/components/layout/wmtHeader.vue'
import WmtFooter from '@/components/layout/wmtFooter.vue'
import StaffDesc from './staffDesc.vue'
import StaffShow from './staffShow.vue'
import JobDesc from './jobDesc.vue'
export default {
  name: 'Home',
  components: {
    WmtHeader,
    WmtFooter,
    StaffDesc,
    StaffShow,
    JobDesc
  },
  data() {
    return {
      activeKey: 'joinUs',
    }
  },
}
</script>

<style lang="less" scoped>
.topbg {
  width: 100%;
  height: 708px;
  object-fit: cover;
}
</style>