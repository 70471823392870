<template>
  <div id="singleModule" style="background: #fff;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>
      <div class="jobEmail">简历投递邮箱: <span> hr@WeMeet.com</span></div>

      <div class="tab">
        <div class="tabItem" @click="changeTab(item)" v-for="(item, index) in tabList" :key="index" :class="[acvtive == item.key ? 'active' : '']">{{ item.name }}</div>
      </div>

      <div class="collapseBg" v-if="acvtive == 'all'">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="Java 开发工程师" name="1">
            <div class="jd">
              <p>
                职位描述：
              </p>
              <p>
                1、担任产品线重大技术项目的架构负责人，并负责架构设计方案并推进落地；
              </p>
              <p>
                2、完成研发平台和框架的评审、开发和调优工作，持续提升优化系统性能，保证系统运行的安全、稳定与效率；
              </p>
              <p>
                3、负责或参与初级架构师的指导、培养；
              </p>
              <p>
                4、根据业界未来发展方向，对产品进行思考与规划，提供统一的框架、平台或组件方案。
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求：
              </p>
              <p>
                1、本科及以上学历，计算机相关专业，6-10年java开发经验，3-5年架构设计经验；
              </p>
              <p>
                2、精通JAVA，扎实的Java编程基础，熟悉常用设计模式、多线程、JVM，包括内存模型、类加载机制以及性能优化，具有系统设计、系统架构能力；
              </p>
              <p>
                3、熟悉高性能、高并发、高可用性分布式系统设计，熟悉RPC、缓存、消息队列、负载均衡、分布式事务等，并能进行系统的调优和优化；
              </p>
              <p>
                4、熟练数据库，大数据相关产品的优先；统筹管理开发过公共组件的优先；
              </p>
              <p>
                5、具有良好的沟通、团队协作能力，架构方案推动和落地能力。
              </p>
              <p>
                6、工作地点在上海，社保交在海南。
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="产品经理" name="2">
            <div class="jd">
              <p>
                职位描述:
              </p>
              <p>
                1、负责产品的生态基础能力建设的设计和规划；
              </p>
              <p>
                2、负责产品的设计和推进工作，对开发、测试、上线等整个过程负责，并对最终产品上线结果负责；
              </p>
              <p>
                3、洞察主流电商、社交、游戏和视频产品及其用户的心理，结合业务情况，设计对应的功能；
              </p>
              <p>
                4、分析产品和用户数据，提升曝光、注册、等核心数据指标；
              </p>
              <p>
                5、定期开展竞品研究、用户访谈，不断优化用户体验，提升小程序竞争力。
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求:
              </p>
              <p>
                1、3年以上大型互联网产品设计经验，有完整的小程序（必须）、服务号、订阅号和视频号设计和上线经验，并取得良好效果，有B+C端产品规划工作经验，擅长交互设计者优先；
              </p>
              <p>
                2、本科及以上学历，软件工程、计算机、电子信息、电子商务等专业优先；
              </p>
              <p>
                3、熟练运用Axure，墨刀，Visio，Xmind等产品设计工具，具备独立撰写PRD文档能力
              </p>
              <p>
                4、熟悉产品从用户调研、业务调研、需求分析到实现过程、产品发布的整个流程；
              </p>
              <p>
                5、熟悉主流的平台电商和社交电商产品，对用户心理有深入洞察；
              </p>
              <p>
                6、熟悉小程序技术原理，熟悉微信生态圈的产品、技术和运营规则；
              </p>
              <p>
                7、用户导向、数据驱动、逻辑清晰，沟通出色；
              </p>
              <p>
                8、产品Owner意识，强自驱力，优秀的协作和项目管理能力，能在复杂的环境下灵活调整；
              </p>
              <p>
                9、有开放平台、区块链和SaaS产品经验优先。
              </p>
              <p>
                10、工作地点在上海，社保可在海南或上海。
              </p>
              <p>
                加分项
              </p>
              <p>
                有以下行业经验：数据服务
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="小程序&H5开发工程师" name="3">
            <div class="jd">
              <p>
                职位描述：
              </p>
              <p>
                1、负责互联网产品的Web前端、公众号、小程序的前端开发;
              </p>
              <p>
                2、与产品经理、UI和后端开发人员协作，快速迭代产品；
              </p>
              <p>
                3、负责前端框架的开发、部署发布及维护；
              </p>
              <p>
                4、帮助团队扩展技术视野及技术深度;
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求：
              </p>
              <p>
                1、必须本科及以上学历，至少3年以上互联网、移动互联网前端开发经验，有大型互联网产品项目开发经验优先；
              </p>
              <p>
                2、基本功扎实，熟悉HTML、CSS，对WEB语义化、用户体验和前端UI规范熟悉。
              </p>
              <p>
                3、熟练移动端H5、公众号及小程序开发，熟练使用Vue及相关技术栈;
              </p>
              <p>
                4、有前后端分离的开发经验，协助Restful API的制定，熟悉至少一种常见前端开发工具，包括Webpack、Grunt等;
              </p>
              <p>
                5、有Web性能优化、移动端H5以及多端适配开发经验和相关经历;
              </p>
              <p>
                6、逻辑清楚，思维清晰，具有软件工程意识，代码规范，具备良好的分析、理解、解决问题的能力;
              </p>
              <p>
                7、有区块链相关工作经验者优先；
              </p>
              <p>
                8、工作地点在上海、社保交在海南。
              </p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="collapseBg" v-if="acvtive == 'yf'">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="Java 开发工程师" name="1">
            <div class="jd">
              <p>
                职位描述：
              </p>
              <p>
                1、担任产品线重大技术项目的架构负责人，并负责架构设计方案并推进落地；
              </p>
              <p>
                2、完成研发平台和框架的评审、开发和调优工作，持续提升优化系统性能，保证系统运行的安全、稳定与效率；
              </p>
              <p>
                3、负责或参与初级架构师的指导、培养；
              </p>
              <p>
                4、根据业界未来发展方向，对产品进行思考与规划，提供统一的框架、平台或组件方案。
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求：
              </p>
              <p>
                1、本科及以上学历，计算机相关专业，6-10年java开发经验，3-5年架构设计经验；
              </p>
              <p>
                2、精通JAVA，扎实的Java编程基础，熟悉常用设计模式、多线程、JVM，包括内存模型、类加载机制以及性能优化，具有系统设计、系统架构能力；
              </p>
              <p>
                3、熟悉高性能、高并发、高可用性分布式系统设计，熟悉RPC、缓存、消息队列、负载均衡、分布式事务等，并能进行系统的调优和优化；
              </p>
              <p>
                4、熟练数据库，大数据相关产品的优先；统筹管理开发过公共组件的优先；
              </p>
              <p>
                5、具有良好的沟通、团队协作能力，架构方案推动和落地能力。
              </p>
              <p>
                6、工作地点在上海，社保交在海南。
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="小程序&H5开发工程师" name="3">
            <div class="jd">
              <p>
                职位描述：
              </p>
              <p>
                1、负责互联网产品的Web前端、公众号、小程序的前端开发;
              </p>
              <p>
                2、与产品经理、UI和后端开发人员协作，快速迭代产品；
              </p>
              <p>
                3、负责前端框架的开发、部署发布及维护；
              </p>
              <p>
                4、帮助团队扩展技术视野及技术深度;
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求：
              </p>
              <p>
                1、必须本科及以上学历，至少3年以上互联网、移动互联网前端开发经验，有大型互联网产品项目开发经验优先；
              </p>
              <p>
                2、基本功扎实，熟悉HTML、CSS，对WEB语义化、用户体验和前端UI规范熟悉。
              </p>
              <p>
                3、熟练移动端H5、公众号及小程序开发，熟练使用Vue及相关技术栈;
              </p>
              <p>
                4、有前后端分离的开发经验，协助Restful API的制定，熟悉至少一种常见前端开发工具，包括Webpack、Grunt等;
              </p>
              <p>
                5、有Web性能优化、移动端H5以及多端适配开发经验和相关经历;
              </p>
              <p>
                6、逻辑清楚，思维清晰，具有软件工程意识，代码规范，具备良好的分析、理解、解决问题的能力;
              </p>
              <p>
                7、有区块链相关工作经验者优先；
              </p>
              <p>
                8、工作地点在上海、社保交在海南。
              </p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="collapseBg" v-if="acvtive == 'cp'">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="产品经理" name="2">
            <div class="jd">
              <p>
                职位描述:
              </p>
              <p>
                1、负责产品的生态基础能力建设的设计和规划；
              </p>
              <p>
                2、负责产品的设计和推进工作，对开发、测试、上线等整个过程负责，并对最终产品上线结果负责；
              </p>
              <p>
                3、洞察主流电商、社交、游戏和视频产品及其用户的心理，结合业务情况，设计对应的功能；
              </p>
              <p>
                4、分析产品和用户数据，提升曝光、注册、等核心数据指标；
              </p>
              <p>
                5、定期开展竞品研究、用户访谈，不断优化用户体验，提升小程序竞争力。
              </p>
              <p>
                <br />
              </p>
              <p>
                职位要求:
              </p>
              <p>
                1、3年以上大型互联网产品设计经验，有完整的小程序（必须）、服务号、订阅号和视频号设计和上线经验，并取得良好效果，有B+C端产品规划工作经验，擅长交互设计者优先；
              </p>
              <p>
                2、本科及以上学历，软件工程、计算机、电子信息、电子商务等专业优先；
              </p>
              <p>
                3、熟练运用Axure，墨刀，Visio，Xmind等产品设计工具，具备独立撰写PRD文档能力
              </p>
              <p>
                4、熟悉产品从用户调研、业务调研、需求分析到实现过程、产品发布的整个流程；
              </p>
              <p>
                5、熟悉主流的平台电商和社交电商产品，对用户心理有深入洞察；
              </p>
              <p>
                6、熟悉小程序技术原理，熟悉微信生态圈的产品、技术和运营规则；
              </p>
              <p>
                7、用户导向、数据驱动、逻辑清晰，沟通出色；
              </p>
              <p>
                8、产品Owner意识，强自驱力，优秀的协作和项目管理能力，能在复杂的环境下灵活调整；
              </p>
              <p>
                9、有开放平台、区块链和SaaS产品经验优先。
              </p>
              <p>
                10、工作地点在上海，社保可在海南或上海。
              </p>
              <p>
                加分项
              </p>
              <p>
                有以下行业经验：数据服务
              </p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div v-if="acvtive != 'all' && acvtive != 'yf' && acvtive != 'cp'" class="noJob">
        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/noJob.png" alt="暂无">
        <p>暂无招聘信息</p>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'jobDesc',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '招聘岗位',
      autoPlay: false,
      activeName: '',
      acvtive: 'all',
      tabList: [
        {
          name: '全部',
          key: 'all',
        },
        {
          name: '研发',
          key: 'yf',
        },
        {
          name: '产品',
          key: 'cp',
        },
        {
          name: '设计',
          key: 'sj',
        },
        {
          name: '测试',
          key: 'cs',
        },
        {
          name: '运营',
          key: 'yy',
        },
        {
          name: '职能/支持',
          key: 'zn',
        },
      ],
    }
  },

  mounted() {},

  methods: {
    goChinaFish() {
      window.open('https://www.cfse-live.com')
    },
    changeTab(e) {
      this.acvtive = e.key
    },
  },
}
</script>
<style>
.el-collapse-item:nth-child(1) .el-collapse-item__header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.el-collapse-item:last-child .el-collapse-item__header {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.el-collapse-item__header {
  background: #def2f3;
  border: 1px solid #b5e1e3;
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  padding-left: 31px;
}
.el-collapse-item__wrap {
  border: 1px solid #b5e1e3;
}
</style>
<style lang="less" scoped>
.moduleContainer {
  position: relative;
  top: -40px;
  .jobEmail {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    span {
      color: #5abec1;
    }
  }
  .collapseBg {
    margin-top: 40px;
    overflow-y: auto;
    height: 500px;
    .jd {
      padding: 27px 31px;

      p {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .tab {
    display: flex;
    background: rgba(90, 190, 193, 0.2);
    height: 70px;
    border-radius: 8px;
    border: 1px solid #5abec1;
    margin-top: 50px;
    .tabItem {
      width: 174px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      &.active {
        font-size: 16px;
        font-weight: 600;
        color: #5abec1;
      }
    }
  }
}
.imgSize {
  width: 1200px;
  height: 670px;
  cursor: pointer;
}

.noJob {
  width: 1200px;
  text-align: center;
  padding-top: 135px;
  p {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>