<template>
  <div id="singleModule" style="background: #fff;height:auto;">
    <div class="moduleContainer">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/joinUs1.png" alt="加入我们" class="imgBox">
      <div class="descBox">
        <div class="centerBox">
          <i class="iconfont wemeet-zhenchenggaoxiao"></i>
          <div>
            真诚高效
          </div>
        </div>
      </div>
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/joinUs3.png" alt="加入我们" class="imgBox">
      <div class="descBox">
        <div class="centerBox">
          <i class="iconfont wemeet-kehuzhishang"></i>
          <div>
            客户至上
          </div>
        </div>
      </div>
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/joinUs/joinUs2.png" alt="加入我们" class="imgBox">
      <div class="descBox">
        <div class="centerBox">
          <i class="iconfont wemeet-kaifangchuangxin"></i>
          <div>
            开放创新
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Staffdesc',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.moduleContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -40px;
  .descBox {
    width: 400px;
    height: 260px;
    background: #5abec1;
    position: relative;
  }
  .imgBox {
    width: 400px;
    height: 260px;
  }
}
.centerBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  i {
    font-size: 70px;
    color: #fff;
  }
  div {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>